import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import FindDesignView from '../views/FindDesignView.vue'
import privacyPolicyView from '../views/privacyPolicyView.vue'
import legalDisclosureView from '../views/legalDisclosureView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'find-design',
    component: FindDesignView
  },
  {
    path: '/privacy-policy',
    name: 'privacy-policy',
    component: privacyPolicyView
  },
  {
    path: '/legal-disclosure',
    name: 'legal-disclosure',
    component: legalDisclosureView
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
