import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'
import { appLocales } from './locales/pxtDesignPortalLocales'
import { createI18n } from 'vue-i18n'
// @ts-ignore
import { Vue3Mq } from 'vue3-mq'
import './css/main.scss'

loadFonts()

const i18n = createI18n({
  legacy: false,
  locale: 'de',
  fallBackLocale: 'en',
  messages: appLocales,
  warnHtmlMessage: false
})

createApp(App)
  .use(router)
  .use(store)
  .use(vuetify)
  .use(i18n)
  .use(Vue3Mq, {
    preset: 'vuetify'
  })
  .mount('#app')
