import { createStore, Store, storeKey } from 'vuex'
import { inputParameterNames, inputParametersType } from 'koch-types/src/pxtDesignPortalFrontend'

export default createStore({
  state: {
    useCase: undefined,
    effectiveMainsVoltage: undefined,
    maximumMainsPower: undefined,
    solutions: undefined,
    slideIndex: {
      value: 0
    },
    inputValues: {
      p0: 0.5,
      p1: 0,
      tP: 0.1,
      tZ: 0.2
    },
    thresholds: {
      p0: {
        minVal: 0.5,
        maxVal: 300,
        stepSize: 0.1
      },
      p1: {
        minVal: 0,
        maxVal: 300,
        stepSize: 0.1
      },
      tZ: {
        minVal: 0.2,
        maxVal: 50,
        stepSize: 0.1
      },
      tP: {
        minVal: 0.1,
        maxVal: 25,
        stepSize: 0.1
      }
    }
  },
  getters: {
  },
  mutations: {
    changeUseCase (state, newState) {
      state.useCase = newState
    },
    setMaximumMainsPower (state, payload) {
      state.maximumMainsPower = payload
    },
    changeEffectiveMainsVoltage (state, newState) {
      state.effectiveMainsVoltage = newState
    },
    updateInputVals (state, payload) {
      state.inputValues[payload.type as keyof inputParametersType] = payload.value
    },
    resetInputVals (state) {
      state.inputValues.tZ = 0.2
      state.inputValues.tP = 0.1
      state.inputValues.p0 = 0.5
      state.inputValues.p1 = 0
    },
    updateThresholds (state, payload) {
      for (const [key, value] of Object.entries(payload)) {
        if (typeof value === 'number') {
          if (key === 'p0' || key === 'p1') {
            state.thresholds[key as inputParameterNames].maxVal = value > 300 ? 300 : value
          } else {
            state.thresholds[key as inputParameterNames].maxVal = value > 50 ? 50 : value
          }
        }
      }
    },
    updateSolutions (state, payload) {
      state.solutions = payload
    },
    incrementSlideIndex (state) {
      state.slideIndex.value++
    }
  },
  actions: {
  },
  modules: {
  }
})
