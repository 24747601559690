import { LocaleMessages, VueMessageType } from 'vue-i18n'

export const appLocales: LocaleMessages<VueMessageType> = {
  en: {
    availableSoon: 'This feature will be available soon.',
    appBarElement: {
      appTitle: ' Design Portal',
      settings: {
        privacyPolicy: 'Privacy Policy',
        impressum: 'Legal Disclosure'
      }
    },
    inputElement: {
      tooltip: {
        pStart: 'Power at the beginning of the load phase',
        pEnd: 'Power at the end of the load phase',
        tBraking: 'Load time',
        tCycle: 'Cycle duration',
        cycleDataInfoToolbar: 'In this area you can define the parameters of your application. They serve as the basis for calculating a solution for your application.'
      },
      depictor: {
        index: {
          start: 'start',
          end: 'end',
          braking: 'load',
          cycle: 'cycle'
        },
        summary: {
          capacitors: 'Capacitor | Capacitors'
        }
      }
    },
    solutionVisualizationElement: {
      noSolution: 'No standard solution found.',
      serverTimeout: 'Unfortunately there has not been any response from the server. Please be patient and try again later.',
      noSolutionNeeded: 'Peak load reduction not necessary',
      accessories: 'Accessories',
      tooltip: {
        noSolution: 'Although no standard solution could be found, you can still reach us via the contact form implemented in the portal to request a special solution without obligation.',
        noSolutionNeeded: 'Peak load reduction is not needed for your application. The grid already provides enough power.',
        productPage: 'Continue to product page',
        dimensions: 'Height, width and depth in mm',
        maxEnergyInput: 'Maximum amount of storable energy'
      },
      tableSpecs: {
        accessories: 'Wiring diagram, fuse holder, fuses',
        amountOfIntegratedCaps: 'Capacitors',
        weight: 'Weight',
        dimensions: 'Dimensions',
        content: 'Scope of delivery',
        uzMaxIEC: 'V<sub>DCmax</sub> (IEC)',
        uzMaxUL: 'V<sub>DCmax</sub> (UL)',
        pMaxCont: 'P<sub>max</sub> (cont.)',
        pMaxPeak: 'P<sub>max</sub> (peak)',
        icMaxCont: 'I<sub>Cmax</sub> (cont.)',
        icMaxPeak: 'I<sub>Cmax</sub> (peak)',
        maxEnergyInput: 'E<sub>max</sub>'
      }
    },
    solutionsListElement: {
      tableHeads: {
        electronicDevices: 'Pxt devices',
        capacitors: 'Capacitors'
      }
    },
    welcomeElement: {
      welcomePhrase: 'Welcome to the Pxt design portal!',
      welcomeMessage: 'Here you will find the suitable Pxt solution for your DC link. Simply follow this input mask and provide the required application data.'
    },
    sendInquiryElement: {
      send: 'Continue'
    },
    selectUseCaseElement: {
      instruction: 'Please select your use case',
      useCase: {
        DES: 'Brake energy management',
        DEV: 'Uninterruptible power supply',
        LSR: 'Peak load reduction'
      }
    },
    selectEffectiveMainsVoltage: {
      instruction1: 'Please select the effective mains voltage of your application',
      instruction2: 'Please provide the following information about your power grid',
      label: 'Effective mains voltage'
    },
    inquiryElement: {
      instructions: {
        productRequest: 'In the area above you can see the solution which has been determined for your system. If you would like to make a non-binding product inquiry for your solution, simply follow this input mask and enter the required inquiry data in the input fields provided.',
        inquirerData: 'Contact data',
        systemConfig: 'Add custom system configurations',
        applicationData: 'Optional application data',
        send: 'You will now be redirected to our website. The contact form will be filled out automatically for you, you only have to confirm your request by clicking the button \'Send message\''
      },
      labels: {
        typeOfUseCase: 'Application environment',
        projectType: 'Project type',
        articleNumberOfFrequencyInverter: 'Article number of frequency inverter',
        frequencyInverterManufacturer: 'Frequency inverter manufacturer',
        powerOfFrequencyInverter: 'Power of frequency inverter',
        dcLinkCapacity: 'DC link capacity',
        brakeChopperActivationThreshold: 'Brake chopper enable threshold',
        powerOfMotor: 'Power of the motor',
        effectivePhaseCurrent: 'Effective phase current',
        numberOfElectricalPhases: 'Number of phases',
        ccdepth: 'Control cabinet depth',
        useCase: 'Use case',
        solution: 'Solution',
        effectiveMainsVoltage: 'Effective mains voltage',
        inputs: 'Power input',
        message: 'Message',
        street: 'Street name + appartment/unit number',
        zipCode: 'ZIP/Postal code',
        city: 'City',
        phone: 'Phone number',
        country: 'Country',
        company: 'Company',
        mail: 'Mail',
        name: 'Full name',
        controlCabinet: 'Complete system as ready-to-use control cabinet',
        communicationAPI: 'PxtMx with EtherCat communication API'
      },
      values: {
        singleProject: 'One-time project',
        seriesProject: 'Series'
      },
      tooltip: {
        typeOfUseCase: 'E.g.: die casting machine, textile machine, servo press',
        effectiveMainsVoltage: '3-phase: phase-to-phase voltage, 1-phase: phase-to-neutral voltage'
      }
    },
    legalDisclosure: {
      headings: {
        legalDisclosure: 'Legal Disclosure',
        owner: 'Owner of the Application',
        dataProtectionOfficer: 'Data Protection Officer',
        dataProtectionAuthority: 'Data Protection Authority',
        exclusionOfLiability: 'Exclusion of Liability'
      },
      keys: {
        company: 'Company',
        CEOs: 'Managing Director',
        address: 'Address',
        telephone: 'Telephone',
        telefax: 'Fax',
        Internet: 'Website',
        email: 'E-Mail',
        legalForm: 'Legal Form',
        UStIdNr: 'USt-IdNo.',
        commercialRegister: 'Commercial Register',
        dataProtectionOfficer:
          'We have appointed a data protection officer: Dr. Christian Wolff, Michael Koch GmbH, Zum Grenzgraben 28, 76698 Ubstadt-Weiher, +49 7251 96 26-200',
        dataProtectionAuthority:
          'The State Commissioner for Data Protection and Freedom of Information of Baden-Württemberg, Königstrasse 10a, 70173 Stuttgart.',
        content: 'Content',
        links: 'Links',
        copyright: 'Copyright'
      },
      values: {
        content:
          'The content of this application has been compiled with the greatest possible care. KOCH, however, cannot guarantee the correctness and accuracy of the information provided. KOCH excludes any liability for damages arising directly or indirectly from the use of this website, unless it can be proven that KOCH acted with intent or gross negligence.',
        links:
          "Insofar as KOCH refers directly or indirectly to external websites by means of links, KOCH cannot accept any liability for the content of these websites. KOCH hereby declares that at the time of linking, the linked pages did not contain any illegal content. KOCH has no influence on the current and future design of the linked sites. Changes to the content of the linked pages after the link was set up are not subject to KOCH's sphere of influence. KOCH is not liable for illegal, incorrect or incomplete content and, in particular, for damages arising from the use or non-use of the information provided on the linked pages.",
        copyright:
          'All texts, images, graphics and other files contained in this application are subject to copyright and other laws protecting intellectual property. They may be used within the scope of the offered use, but may not be copied or modified for commercial purposes or for distribution and used on other websites. This application does not grant any license to use the intellectual property of KOCH or third parties. All brand names and trademarks mentioned on the website and, if applicable, protected by third parties, are subject without restriction to the provisions of the applicable trademark law and the ownership rights of the respective registered owners. The mere mention of a trademark does not imply that it is not protected by the rights of third parties.'
      }
    }
  },
  de: {
    availableSoon: 'Bald verfügbar',
    appBarElement: {
      appTitle: '-Auslegungsportal',
      settings: {
        privacyPolicy: 'Datenschutzerklärung',
        impressum: 'Impressum'
      }
    },
    legalDisclosure: {
      headings: {
        legalDisclosure: 'Impressum',
        owner: 'Inhaber der Applikation',
        dataProtectionOfficer: 'Datenschutzbeauftragter',
        dataProtectionAuthority: 'Datenschutzaufsichtsbehörde',
        exclusionOfLiability: 'Haftungsausschluss'
      },
      keys: {
        company: 'Firma',
        CEOs: 'Geschäftsführer',
        address: 'Anschrift',
        telephone: 'Telefon',
        telefax: 'Telefax',
        Internet: 'Webseite',
        email: 'E-Mail',
        legalForm: 'Rechtsform',
        UStIdNr: 'USt-IdNr.',
        commercialRegister: 'Handelsregister',
        dataProtectionOfficer:
          'Wir haben einen Datenschutzbeauftragten bestellt: Dr. Christian Wolff, Michael Koch GmbH, Zum Grenzgraben 28, 76698 Ubstadt-Weiher, +49 7251 96 26-200',
        dataProtectionAuthority:
          'Der Landesbeauftragte für den Datenschutz und die Informationsfreiheit Baden-Württemberg, Königstrasse 10a, 70173 Stuttgart.',
        content: 'Inhalt',
        links: 'Links',
        copyright: 'Urheberrecht'
      },
      values: {
        content:
          'Der Inhalt dieser Applikation wurde mit größtmöglicher Sorgfalt zusammengestellt. KOCH, kann jedoch keine Gewähr für die Fehlerfreiheit und Genauigkeit der bereitgestellten Informationen übernehmen. KOCH schließt jegliche Haftung für Schäden aus, die direkt oder indirekt aus der Benutzung dieser Website entstehen, sofern seitens KOCH kein nachweislich vorsätzliches oder grob fahrlässiges Verschulden vorliegt.',
        links:
          'Sofern KOCH mit Links direkt oder indirekt auf fremde Internetseiten verweist, kann KOCH für den Inhalt dieser Websites keine Haftung übernehmen. KOCH erklärt hiermit, dass zum Zeitpunkt der Linksetzung die verlinkten Seiten keine illegalen Inhalte enthalten haben. KOCH hat keinen Einfluss auf die aktuelle und zukünftige Gestaltung der verlinkten Seiten. Inhaltlichen Änderungen, die nach der Linksetzung auf den verlinkten Seiten vorgenommen werden, unterliegen nicht dem Einflussbereich von KOCH. KOCH haftet nicht für illegale, fehlerhafte oder unvollständige Inhalte und insbesondere für Schäden, die durch Nutzung oder Nichtnutzung der auf den verlinkten Seiten angebotenen Informationen entstehen.',
        copyright:
          'Alle Texte, Bilder, Grafiken und sonstige in dieser Applikation enthaltenen Dateien unterliegen dem Urheberrecht und anderen Gesetzen zum Schutz des geistigen Eigentums. Sie dürfen im Rahmen der angebotenen Verwendung genutzt werden, jedoch weder für Handelszwecke noch zur Weitergabe kopiert oder verändert und auf anderen Websites verwendet werden. Durch diese Applikation wird keine Lizenz zur Nutzung des geistigen Eigentums von KOCH oder Dritten erteilt. Alle innerhalb des Internetangebots genannten und gegebenenfalls durch Dritte geschützten Marken- und Warenzeichen unterliegen uneingeschränkt den Bestimmungen des jeweils gültigen Kennzeichenrechts und den Besitzrechten der jeweiligen eingetragenen Eigentümer. Allein aufgrund der bloßen Nennung ist nicht der Schluss zu ziehen, dass Markenzeichen nicht durch Rechte Dritter geschützt sind.'
      }
    },
    inputElement: {
      tooltip: {
        pStart: 'Leistung zu Beginn der Lastphase',
        pEnd: 'Leistung zum Ende der Lastphase',
        tBraking: 'Dauer der Last',
        tCycle: 'Dauer des Zyklus',
        cycleDataInfoToolbar: 'In diesem Bereich können Sie die Zyklusparameter Ihrer Anwendung hinterlegen. Für die eingegebenen Parameterdaten werden dann mögliche Lösungen abgerufen.'
      },
      depictor: {
        index: {
          start: 'Start',
          end: 'Ende',
          braking: 'Last',
          cycle: 'Zyklus'
        },
        summary: {
          capacitors: 'Kondensator | Kondensatoren'
        }
      }
    },
    solutionVisualizationElement: {
      noSolution: 'Keine Standardlösung gefunden',
      serverTimeout: 'Leider konnte keine Antwort vom Server empfangen werden. Bitte haben Sie Geduld und versuchen Sie es später noch einmal.',
      noSolutionNeeded: 'Lastspitzenreduktion nicht notwendig',
      accessories: 'Zubehör',
      tooltip: {
        noSolution: 'Es konnte zwar keine Standardlösung gefunden werden, Sie können uns dennoch über das im Portal implementierte Kontaktformular erreichen, um unverbindlich eine Sonderlösung anzufragen.',
        noSolutionNeeded: 'Eine Lastspitzenreduktion wird für Ihre Anwendung nicht benötigt. Das Netz stellt bereits genug Leistung zur Verfügung.',
        productPage: 'Weiter zur Produktseite',
        dimensions: 'Höhe x Breite x Tiefe in mm',
        uzMaxIEC: 'Maximale Betriebsspannung nach IEC',
        uzMaxUL: 'Maximale Betriebsspannung nach UL',
        pMaxCont: 'Maximale Dauerleistung',
        pMaxPeak: 'Maximale Spitzenleistung',
        icMaxCont: 'Maximaler Speicherstrom bei Dauerlast',
        icMaxPeak: 'Maximaler Speicherstrom bei Spitzenlast (60s Last bei 240s Zykluszeit)',
        maxEnergyInput: 'Maximale Menge an speicherbarer Energie'
      },
      tableSpecs: {
        accessories: 'Schaltplan, Sicherungshalter, Sicherungen',
        weight: 'Gewicht',
        amountOfIntegratedCaps: 'Speicher',
        content: 'Lieferumfang',
        dimensions: 'Abmessungen',
        uzMaxIEC: 'U<sub>Zmax</sub> (IEC)',
        uzMaxUL: 'U<sub>Zmax</sub> (UL)',
        pMaxCont: 'P<sub>max</sub> (Dauer)',
        pMaxPeak: 'P<sub>max</sub> (Spitze)',
        icMaxCont: 'I<sub>Cmax</sub> (Dauer)',
        icMaxPeak: 'I<sub>Cmax</sub> (Spitze)',
        maxEnergyInput: 'E<sub>max</sub>'
      }
    },
    solutionsListElement: {
      tableHeads: {
        electronicDevices: 'Pxt-Geräte',
        capacitors: 'Kondensatoren'
      }
    },
    welcomeElement: {
      welcomePhrase: 'Herzlich Willkommen im Pxt-Auslegungsportal!',
      welcomeMessage: 'Hier finden Sie die passende Pxt-Lösung für Ihren DC-Zwischenkreis. Folgen Sie dazu dieser Eingabemaske und hinterlegen Sie die benötigten Anwendungsdaten.'
    },
    sendInquiryElement: {
      send: 'Weiter'
    },
    selectUseCaseElement: {
      instruction: 'Bitte wählen Sie Ihren Anwendungsfall',
      useCase: {
        DES: 'Bremsenergie-Management',
        DEV: 'Unterbrechungsfreie Stromversorgung',
        LSR: 'Lastspitzenreduktion'
      }
    },
    selectEffectiveMainsVoltage: {
      instruction1: 'Bitte wählen Sie die effektive Netzspannung Ihrer Anwendung',
      instruction2: 'Bitte hinterlegen Sie die nachfolgenden Informationen zu Ihrem Stromnetz',
      label: 'Effektive Netzspannung'
    },
    inquiryElement: {
      instructions: {
        productRequest: 'Im Bereich oben sehen Sie die für Ihr System ermittelte Lösung. Falls Sie zu Ihrer Lösung eine unverbindliche Produktanfrage stellen möchten, folgen Sie einfach dieser Eingabemaske und hinterlegen Sie die benötigten Anfragedaten in den dafür vorgesehenen Eingabefeldern.',
        inquirerData: 'Kontaktdaten:',
        systemConfig: 'Systemkonfigurationen hinzufügen:',
        applicationData: 'Optionale Anwendungsdaten:',
        send: 'Sie werden nun auf unsere Webseite weitergeleitet. Das Kontaktformular wird automatisch für Sie ausgefüllt, sie müssen Ihre Anfrage dann nur noch über den Button "Nachricht senden" bestätigen'
      },
      labels: {
        typeOfUseCase: 'Anwendungsumgebung',
        projectType: 'Projekttyp',
        articleNumberOfFrequencyInverter: 'Artikelnummer des Frequenzumrichters',
        frequencyInverterManufacturer: 'Frequenzumrichter-Hersteller',
        powerOfFrequencyInverter: 'Leistung des Frequenzumrichters',
        dcLinkCapacity: 'Zwischenkreiskapazität',
        brakeChopperActivationThreshold: 'Bremschoppereinschaltschwelle',
        powerOfMotor: 'Leistung des Motors',
        effectivePhaseCurrent: 'Effektiver Phasenstrom',
        effectivePhaseVoltage: 'Effektive Phasenspannung',
        numberOfElectricalPhases: 'Anzahl der Phasen',
        ccdepth: 'Schaltschranktiefe',
        useCase: 'Anwendungsfall',
        solution: 'Lösung',
        effectiveMainsVoltage: 'Effektive Netzspannung',
        inputs: 'Leistungseingabe',
        message: 'Nachricht',
        street: 'Straße + Hausnummer',
        zipCode: 'Postleitzahl',
        city: 'Stadt',
        country: 'Land',
        phone: 'Telefon',
        company: 'Unternehmen',
        mail: 'E-Mail',
        name: 'Vorname, Nachname',
        controlCabinet: 'Komplettsystem als einsatzbereiter Schaltschrank',
        communicationAPI: 'PxtMx mit EtherCat-Kommunikationsschnittstelle'
      },
      values: {
        singleProject: 'Einmaliges Projekt',
        seriesProject: 'Serie'
      },
      tooltip: {
        typeOfUseCase: 'z.B.: Druckgussmaschine, Textilmaschine, Regalbediengerät',
        effectiveMainsVoltage: '3-Phasig: Phase-Phase-Spannung, 1-Phasig: Phase-Neutral-Spannung'
      }
    }
  }
}
